.infra {
  background: $whiteBg;
  padding: 100px 0;

  @media (min-width: $largebp) {
    padding-right: 80px;
  }

  &__title {
    font-size: 28px;
    margin-bottom: 20px;
    max-width: 400px;
    text-transform: none;
    text-transform: uppercase;

    @media (min-width: $largebp) {
      margin-bottom: 120px;
      font-size: 45px;
      max-width: 560px;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: $largebp) {
      flex-direction: row;
    }
  }
}

.infra-floor {
  width: 100%;

  order: 99;
  margin-top: 40px;

  @media (min-width: $largebp) {
    width: 25%;
    order: initial;
    margin-top: 0;
  }

  .infra--activated & {
    img {
      width: 100%;
      max-width: 227px;
      margin: 0 auto;

      @media (min-width: $largebp) {
        max-width: 396px;
      }
    }
  }

  img {
    display: none;
  }

  .infra--active-1 & img:nth-of-type(5) {
    display: block;
  }

  .infra--active-2 & img:nth-of-type(4) {
    display: block;
  }

  .infra--active-3 & img:nth-of-type(3) {
    display: block;
  }

  .infra--active-4 & img:nth-of-type(2) {
    display: block;
  }

  .infra--active-5 & img:nth-of-type(1) {
    display: block;
  }
}

.infra-building {
  position: relative;
  width: 100%;

  @media (min-width: $largebp) {
    margin: 0 auto;
    max-width: 60%;
  }

  img {
    position: relative;
    z-index: 2;
    pointer-events: none;
  }

  &__layer {
    span {
      display: block;
      opacity: 0;
      transition: 0.2s opacity;
      background-color: #3da75f;
    }

    &:hover span {
      opacity: 1;
    }

    &--one {
      span {
        .infra--active-1 & {
          opacity: 1;
        }
      }

      span.one {
        width: 88.5%;
        height: 14.45%;
        position: absolute;
        top: 15.45%;
        left: 4.5%;
      }

      span.two {
        width: 95.7%;
        height: 4.2%;
        position: absolute;
        top: 29.95%;
        left: 1.8%;
      }
    }

    &--two span {
      width: 97.5%;
      height: 15.4%;
      position: absolute;
      top: 33.95%;
      left: 0;

      .infra--active-2 & {
        opacity: 1;
      }
    }

    &--three span {
      width: 97.5%;
      height: 15.4%;
      position: absolute;
      top: 49.74%;
      left: 0;

      .infra--active-3 & {
        opacity: 1;
      }
    }

    &--four span {
      width: 100%;
      height: 15.4%;
      position: absolute;
      top: 65.2%;
      left: 0;

      .infra--active-4 & {
        opacity: 1;
      }
    }

    &--five span {
      width: 100%;
      height: 19.4%;
      position: absolute;
      top: 80.7%;
      left: 0;

      .infra--active-5 & {
        opacity: 1;
      }
    }
  }
}

.mobile-floor-info {
  text-align: center;
  width: 100%;
  margin-top: 40px;

  @media (min-width: $largebp) {
    display: none;
  }

  .infra--activated & {
    display: none;
  }

  p {
    display: inline-flex;
    border-bottom: 1px solid $darkGreen;
    padding-bottom: 12px;
  }
}

.mobile-floor-selector {
  width: 100%;
  margin-top: 32px;

  @media (min-width: $largebp) {
    width: auto;
    margin: 0;
    align-self: stretch;
  }

  ul {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    @media (min-width: $largebp) {
      flex-direction: column;
      gap: 0px;
      height: 100%;
      justify-content: flex-end;
    }
  }

  &__floor {
    user-select: none;
    cursor: pointer;
    font-size: 14px;
    font-family: $font;
    color: $darkGreen;
    border-bottom: 1px solid $darkGreen;

    @media (min-width: $largebp) {
      text-align: center;
      height: 16.5%;
      width: 30px;
      align-items: center;
      display: flex;
      border-bottom: none;
    }

    &:nth-of-type(1) {
      // background: red;
      height: 20%;
    }

    &:nth-of-type(2) {
      // background: orange;
      height: 15%;
    }

    &:nth-of-type(3) {
      // background: green;
      height: 15%;
    }

    &:nth-of-type(4) {
      // background: blue;
      height: 15%;
    }

    &:nth-of-type(5) {
      // background: teal;
      height: 20%;
    }

    h2 {
      font-size: 14px;

      @media (min-width: $largebp) {
        font-weight: 400;
        font-family: $font;
        border-bottom: 1px solid $darkGreen;
      }

      small {
        display: none;
      }
    }

    &--active {
      border-bottom-color: transparent;

      h2 {
        font-family: $font;
        color: $limeGreen;
        font-size: 31px;
        border-color: transparent;

        @media (min-width: $largebp) {
          font-size: 14px;
          font-weight: bold;
          font-family: $font2;
        }

        small {
          font-size: 14px;
          font-weight: bold;
          display: inline-block;
          margin-left: 5px;

          @media (min-width: $largebp) {
            display: none;
          }
        }
      }
    }
  }
}

.infra-highlights-wrap {
  width: 100%;
  margin-top: 32px;

  @media (min-width: $largebp) {
    margin-top: 100px;
  }
}

.infra-highlights {
  display: flex;
  flex-direction: column;
  gap: 20px;
  display: none;

  @media (min-width: $largebp) {
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  &--none {
    display: flex;

    .infra--activated & {
      display: none;
    }
  }

  .infra--active-1 &--4 {
    display: flex;
  }

  .infra--active-2 &--3 {
    display: flex;
  }

  .infra--active-3 &--2 {
    display: flex;
  }

  .infra--active-4 &--1 {
    display: flex;
  }

  .infra--active-5 &--5 {
    display: flex;
  }

  &__highlight {
    border-left: 1px solid $darkGreen;
    padding-left: 20px;
    padding: 20px 0 20px 20px;

    @media (min-width: $largebp) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      width: 100%;
      max-width: 35%;
      height: 140px;
      padding: 0;
      padding-left: 32px;
    }

    &--no-pad {
      padding: 0px 0 0px 20px;
    }

    &--flex {
      flex: 1;
    }

    &--small {
      max-width: 300px;
    }

    &--fill {
      max-width: none;
    }

    &--floor {
      display: none;
      border-left: none;

      @media (min-width: $largebp) {
        display: flex;
        padding-right: 0px;
        max-width: 300px;
        flex: initial;
      }
    }

    &--floor-ground {
      @media (min-width: $xlargebp) {
        width: 350px;
      }
    }

    &--logo {
      display: none;
      border-left: none;
      color: $limeGreen;

      @media (min-width: $largebp) {
        display: flex;
        max-width: 300px;
      }

      svg {
        width: 226px;
      }
    }

    &--tap {
      max-width: 210px;
      border: none;
      display: none;

      @media (min-width: $largebp) {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      p {
        border-bottom: 1px solid $darkGreen;
        padding: 15px 0;
        font-size: 16px;
        text-transform: uppercase;

        @media (min-width: $xlargebp) {
          font-size: 20px;
        }
      }
    }
  }

  h2 {
    font-weight: 300;
    font-size: 44px;
    font-family: $font;
    color: $limeGreen;

    @media (min-width: $xlargebp) {
      font-size: 70px;
    }

    small {
      font-size: 20px;
      font-weight: 700;
      font-weight: 400;
    }
  }

  h3 {
    font-size: 22px;

    @media (min-width: $largebp) {
      font-size: 22px;
    }

    @media (min-width: $xlargebp) {
      font-size: 35px;
    }

    small {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;

      @media (min-width: $xlargebp) {
        font-size: 20px;
      }
    }
  }

  h4,
  p {
    font-family: $font;
    font-weight: 300;
    margin-top: 10px;
    max-width: 380px;

    @media (min-width: $largebp) {
      font-size: 20px;
    }
  }
}

.infra-icons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
  margin: 0 auto;

  @media (min-width: $largebp) {
    font-size: 20px;
    gap: 28px;
  }

  h5 {
    font-family: $font;
    font-size: 14px;
    margin-top: 5px;
  }

  &__icon {
    text-align: center;

    svg {
      margin: 0 auto;
      height: 60px;
      width: auto;
      color: $darkGreen;

      @media (min-width: $largebp) {
        height: 80px;
      }
    }
  }
}
