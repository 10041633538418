.epicenter {
  // background: $green;
  color: $white;
  padding-bottom: 80px;

  @media (min-width: $largebp) {
    padding-right: 80px;
  }

  &__flex {
    display: flex;
    gap: 60px;
    flex-direction: column;

    @media (min-width: $largebp) {
      flex-direction: row;
      align-items: center;
    }

    @media (min-width: $xlargebp) {
      gap: 120px;
    }
  }

  &__left {
    @media (min-width: $largebp) {
      width: 45%;
      max-width: 540px;
    }
  }

  &__image {
    flex: 1;
    order: -1;
    margin-right: -20px;

    @media (min-width: $xlargebp) {
      order: 2;
      margin-right: 0;
    }
  }

  &__heading {
    text-transform: none;
    font-size: 28px;
    margin-bottom: 20px;

    @media (min-width: $largebp) {
      font-size: 45px;
    }
  }

  &__p {
    font-size: 15px;
    line-height: 1.2;
    max-width: 287px;

    @media (min-width: $largebp) {
      font-size: 20px;
      max-width: none;
    }
  }
}
