.specs {
  background: $darkGreen;
  color: $white;
  padding-bottom: 100px;

  @media (min-width: $largebp) {
    padding: 130px 0;
    padding-right: 80px;
  }

  .width {
    position: relative;
  }

  &__content {
    @media (min-width: $largebp) {
      width: 50%;
      margin: 0 0 0 auto;
    }
  }

  &__top {
    text-align: center;
    padding: 100px 20px 70px;

    @media (min-width: $largebp) {
      background: transparent;
      padding: 0;
      text-align: left;
    }
  }

  &__title {
    font-size: 26px;
    max-width: 350px;
    line-height: 1.2;
    margin: 0 auto;
    text-transform: uppercase;

    @media (min-width: $largebp) {
      font-size: 42px;
      max-width: none;
      margin: 0 0 40px;
      line-height: 1.1;

      letter-spacing: -0.0625em;
    }

    @media (min-width: $xlargebp) {
      font-size: 62px;
    }
  }

  &__divider {
    background: $white;
    height: 1px;
    width: 100%;
    max-width: 400px;
    margin: 100px auto 50px;

    @media (min-width: $largebp) {
      display: none;
    }
  }

  &__subtitle {
    font-size: 22px;
    font-weight: 300;

    @media (min-width: $largebp) {
      font-size: 32px;
    }

    @media (min-width: $xlargebp) {
      font-size: 45px;
    }
  }

  &__video {
    margin-top: 40px;

    @media (min-width: $largebp) {
      display: none;
    }
  }

  &__logo {
    padding: 0 20px;
    margin-bottom: 60px;

    @media (min-width: $largebp) {
      display: none;
      margin-top: 0;
    }

    svg {
      width: 226px;
      margin: 0 auto;
    }
  }

  &__image {
    margin: 0;
    width: calc(100% - 20px);

    @media (min-width: $largebp) {
      max-width: calc(50%);
      position: absolute;
      top: 50%;
      left: -40px;
      transform: translateY(-50%);
    }

    @media (min-width: $xlargebp) {
      max-width: calc(50% - 20px);
    }
  }
}

.specs-items {
  padding: 0 20px;
  max-width: 350px;
  margin: 60px auto 0;

  @media (min-width: $largebp) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    max-width: none;
    padding: 0;
  }

  &__item {
    border-left: 1px solid $white;
    padding-left: 40px;
    padding-bottom: 40px;

    @media (min-width: $largebp) {
      padding-left: 20px;
    }

    @media (min-width: $xlargebp) {
      padding-left: 20px;
    }

    strong {
      font-size: 28px;
      display: block;
      margin-bottom: 12px;
      font-weight: 300;
      font-size: clamp(31px, 2.8vw, 44px);
      color: $limeGreen;
    }

    span {
      font-size: 15px;

      @media (min-width: $largebp) {
        font-size: 18px;
      }

      @media (min-width: $xlargebp) {
        font-size: 22px;
      }
    }
  }

  &__item:last-of-type {
    padding-bottom: 0;
  }

  &__item:nth-of-type(1) {
    @media (min-width: $largebp) {
      order: 1;
      width: 40%;
    }
  }

  &__item:nth-of-type(2) {
    @media (min-width: $largebp) {
      order: 3;
      width: 40%;
      padding-bottom: 0;
    }
  }

  &__item:nth-of-type(3) {
    @media (min-width: $largebp) {
      order: 2;
    }
  }

  &__item:nth-of-type(4) {
    @media (min-width: $largebp) {
      order: 4;
      padding-bottom: 0;
    }
  }
}
