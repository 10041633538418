@font-face {
  font-family: "Gotham";
  src: url("./fonts/subset-Gotham-Light.woff2") format("woff2"),
    url("./fonts/subset-Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/subset-Gotham-Medium.woff2") format("woff2"),
    url("./fonts/subset-Gotham-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Simplon Norm";
  src: url("./fonts/subset-SimplonNorm-Light.woff2") format("woff2"),
    url("./fonts/subset-SimplonNorm-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Simplon Norm";
  src: url("./fonts/subset-SimplonNorm.woff2") format("woff2"),
    url("./fonts/subset-SimplonNorm.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Simplon Norm";
  src: url("./fonts/subset-SimplonNorm-MediumItalic.woff2") format("woff2"),
    url("./fonts/subset-SimplonNorm-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Simplon Norm";
  src: url("./fonts/subset-SimplonNorm-Italic.woff2") format("woff2"),
    url("./fonts/subset-SimplonNorm-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Simplon Norm";
  src: url("./fonts/subset-SimplonNorm-Medium.woff2") format("woff2"),
    url("./fonts/subset-SimplonNorm-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Simplon Norm";
  src: url("./fonts/subset-SimplonNorm-LightItalic.woff2") format("woff2"),
    url("./fonts/subset-SimplonNorm-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Simplon Norm";
  src: url("./fonts/subset-SimplonNorm-Bold.woff2") format("woff2"),
    url("./fonts/subset-SimplonNorm-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Simplon Norm";
  src: url("./fonts/subset-SimplonNorm-BoldItalic.woff2") format("woff2"),
    url("./fonts/subset-SimplonNorm-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
