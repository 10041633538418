$largebp: 1020px;
$xlargebp: 1320px;
$xxlargebp: 1440px;

$font: "Simplon Norm", serif;
$font2: "Gotham", serif;

$white: #f0ede5;
$whiteBg: #ffffff;

$green: #30573b;
$darkGreen: #003e35;
$lightBrownBg: #f0ede5;
$xlightBrownBg: #f7f6f2;

$limeGreen: #36a55d;

$fadeOff: left 0s 0.15s, opacity 0.15s;
$fadeOn: left 0s, opacity 0.15s;
