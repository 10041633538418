.plan {
  padding: 100px 0 50px;
  background: $white;

  @media (min-width: $largebp) {
    padding: 200px 80px 200px 0;
  }

  .width {
    @media (min-width: $largebp) {
      position: relative;
    }
  }

  &__title {
    font-size: 28px;
    text-transform: none;
  }

  &__desc {
    font-size: 15px;
    max-width: 280px;
    margin-top: 30px;
    font-weight: 300;
    line-height: 1.2;

    @media (min-width: $largebp) {
      position: absolute;
      bottom: 0;
      right: 170px;
      max-width: none;
      width: calc(100% - 400px);
      font-size: 17px;
    }

    span {
      text-align: center;

      @media (min-width: $largebp) {
        max-width: 482px;
        display: block;
        margin: 0 auto;
      }
    }
  }

  &__image {
    margin: 30px 0 30px;
    max-width: 600px;

    @media (min-width: $largebp) {
      padding-right: 60px;
      max-width: none;
      width: calc(100% - 170px);
    }

    @media (min-width: $xlargebp) {
      position: absolute;
      padding-left: 290px;
    }

    img {
      @media (min-width: $xlargebp) {
        width: 876px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  .legend {
    margin-bottom: 30px;

    @media (min-width: $xlargebp) {
      margin-top: 210px;
    }
  }

  .legend__color {
    &--one {
      background: #c7c4e2;
    }

    &--two {
      background: #a7a8d2;
    }

    &--three {
      background: #c4d3e2;
    }

    &--four {
      background: #a5bdd8;
    }

    &--five {
      background: #eee;
    }
  }

  &__stats {
    @media (min-width: $largebp) {
      position: absolute;
      width: 170px;
      right: 0;
      top: 0;
    }
  }

  &__subtitle {
    margin-top: 60px;
    margin-bottom: 24px;
    text-transform: none;
    font-size: 19px;
  }

  &__item {
    padding-bottom: 20px;
    padding-left: 20px;
    margin-left: 60px;
    border-left: 1px solid $green;

    @media (min-width: $largebp) {
      padding-bottom: 40px;
      padding-left: 30px;
      margin-left: 0;
    }

    strong {
      font-size: 19px;
      display: block;

      @media (min-width: $largebp) {
        font-size: 25px;
        margin-bottom: 5px;
      }
    }

    span {
      font-size: 15px;
      font-weight: 300;

      @media (min-width: $largebp) {
        font-size: 17px;
      }
    }

    br {
      display: none;

      @media (min-width: $largebp) {
        display: initial;
      }
    }
  }

  &__item:last-of-type {
    padding-bottom: 0;
  }

  &__download {
    margin-top: 60px;
    text-align: center;

    @media (min-width: $largebp) {
      text-align: left;
      margin-top: 120px;
    }
  }
}
