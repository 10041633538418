.leader {
  background: #fff;

  @media (min-width: $largebp) {
    padding-right: 80px;
  }

  .width {
    border-top: 1px solid $darkGreen;
    padding: 100px 0;
  }

  &__top {
    @media (min-width: $largebp) {
      display: flex;
      gap: 60px;
    }

    @media (min-width: $xlargebp) {
      gap: 60px;
    }

    svg {
      margin: 0 auto;
      @media (min-width: $largebp) {
        display: none;
      }
    }
  }

  &__top-image {
    width: 100%;
    margin: 32px 0;

    @media (min-width: $largebp) {
      margin: 0;
      width: 55%;
    }

    @media (min-width: $xlargebp) {
      width: 50%;
    }
  }

  &__top-text {
    @media (min-width: $largebp) {
      width: 45%;
      display: flex;
      flex-direction: column;
    }

    @media (min-width: $xlargebp) {
      width: 50%;
    }
  }

  &__top-text-inner {
    max-width: 310px;
    margin: 0 auto;

    @media (min-width: $largebp) {
      max-width: 600px;
    }

    svg {
      display: none;
      margin: 0;

      @media (min-width: $largebp) {
        display: block;
      }
    }

    i {
      font-style: italic;
    }

    h2 {
      font-size: 22px;
      font-weight: 300;
      margin-bottom: 24px;

      @media (min-width: $largebp) {
        margin-top: 60px;
        font-size: 35px;
      }

      @media (min-width: $xlargebp) {
        font-size: 45px;
        margin-top: 100px;
      }
    }

    p {
      font-size: 14px;

      @media (min-width: $largebp) {
        margin-top: 32px;
        font-size: 16px;
      }

      @media (min-width: $xlargebp) {
        font-size: 20px;
      }
    }
  }

  &__bottom {
    margin-top: 60px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;

    @media (min-width: 640px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: $largebp) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.leader-item {
  border-left: 1px solid $darkGreen;
  padding: 0 0 0 20px;

  &__icon {
    height: 80px;
    display: flex;
    align-items: center;

    svg {
      transform: scale(0.75);

      @media (min-width: $largebp) {
        transform: none;
      }
    }
  }

  p {
    font-size: 14px;

    @media (min-width: $largebp) {
      font-size: 18px;
      margin-top: 10px;
    }

    @media (min-width: $xlargebp) {
      font-size: 20px;
    }
  }
}
