.research {
  padding: 100px 0;
  background: $xlightBrownBg;
  position: relative;
  overflow: hidden;

  @media (min-width: $largebp) {
    padding: 200px 100px 120px 0;
  }

  &__text {
    max-width: 320px;
    position: relative;
    z-index: 2;

    @media (min-width: $largebp) {
      max-width: 660px;
    }
  }

  &__title {
    font-size: 28px;
    text-transform: uppercase;

    @media (min-width: $largebp) {
      font-size: 45px;
    }
  }

  &__divider {
    background-color: $green;
    height: 2px;
    width: 24px;
    margin: 20px 0 12px;
  }

  &__subtitle {
    font-size: 22px;

    @media (min-width: $largebp) {
      font-size: 35px;
      max-width: 575px;
    }
  }

  &__logo svg {
    position: absolute;
    height: 80%;
    width: auto;
    top: 50%;
    transform: translate(30%, -50%);
    right: 79px;
    color: $lightBrownBg;
  }
}

.research-slider {
  width: calc(100% - 34px);
  margin: 50px auto 0;
  position: relative;
  z-index: 2;

  @media (min-width: $largebp) {
    width: 90%;
    max-width: 1400px;
    margin-right: 0;
  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: $green;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .slick-next {
    right: -17px;
    background-image: url("./images/right-arrow-white.svg");
  }

  .slick-prev {
    left: -17px;
    background-image: url("./images/left-arrow-white.svg");
  }

  .slick-dots {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    left: 50%;
    top: 100%;
    margin-top: 5px;
    transform: translateX(-50%);

    @media (min-width: $largebp) {
      top: auto;
      bottom: 50px;
    }

    li {
      height: 8px;
      display: block;
    }

    button {
      width: 6px;
      height: 6px;
      font-size: 0;
      border: 1px solid $green;
      border-radius: 50%;
      transition: 0.2s background-color;

      @media (min-width: $largebp) {
        border: 2px solid $white;
      }
    }

    .slick-active button {
      background: $green;

      @media (min-width: $largebp) {
        background: $white;
      }
    }
  }

  &__taglines {
    text-align: center;
    max-width: 70%;
    margin: 50px auto 0;
    font-size: 14px;

    @media (min-width: $largebp) {
      margin-top: 20px;
      font-size: 20px;
    }
  }

  &__tagline {
    max-width: 230px;
    margin: 0 auto;

    @media (min-width: $largebp) {
      max-width: none;
    }
  }
}
