.logos {
  padding: 0 0 60px 0;

  @media (min-width: $largebp) {
    padding: 0 80px 100px 0;
  }

  &__logos-outer {
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    display: flex;
    justify-content: center;
  }

  &__logos {
    text-align: center;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    row-gap: 30px;
    padding: 45px 0;
    max-width: 1200px;
    margin: 0 auto;

    @media (min-width: $largebp) {
      column-gap: 80px;
      row-gap: 60px;
    }
  }

  &__logo {
    display: inline-flex;

    &--af {
      width: calc(162px * 0.3); // Max size is 50% of 2x

      @media (min-width: $largebp) {
        width: calc(162px * 0.5); // Slightly smaller on mobile
      }
    }

    &--cityfitness {
      width: calc(364px * 0.3);

      @media (min-width: $largebp) {
        width: calc(364px * 0.5);
      }
    }

    &--fourseasons {
      width: calc(332px * 0.3);

      @media (min-width: $largebp) {
        width: calc(332px * 0.5);
      }
    }

    &--lacolombe {
      width: calc(208px * 0.3);

      @media (min-width: $largebp) {
        width: calc(208px * 0.5);
      }
    }

    &--ritzcarlton {
      width: calc(306px * 0.3);

      @media (min-width: $largebp) {
        width: calc(306px * 0.5);
      }
    }

    &--shakeshack {
      width: calc(340px * 0.3);

      @media (min-width: $largebp) {
        width: calc(340px * 0.5);
      }
    }

    &--solidcore {
      width: calc(350px * 0.3);

      @media (min-width: $largebp) {
        width: calc(350px * 0.5);
      }
    }

    &--ua1 {
      width: calc(250px * 0.3);

      @media (min-width: $largebp) {
        width: calc(250px * 0.5);
      }
    }

    &--starbucks {
      width: calc(278px * 0.3);

      @media (min-width: $largebp) {
        width: calc(278px * 0.5);
      }
    }

    &--sweetgreen {
      width: calc(314px * 0.3);

      @media (min-width: $largebp) {
        width: calc(314px * 0.5);
      }
    }

    &--traderjoes {
      width: calc(318px * 0.3);

      @media (min-width: $largebp) {
        width: calc(318px * 0.5);
      }
    }
  }
}
