@keyframes slideAndFade {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: translateY(5px);
  }
  100% {
    opacity: 0;
    transform: translateY(5px);
  }
}

.top {
  position: relative;
  color: $white;
  background: $darkGreen;

  video {
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;

    @media (min-width: $largebp) {
      height: 100vh;
    }
  }

  .top__button {
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;

    @media (min-width: $largebp) {
      display: inline-flex;
    }
  }

  .top__scroll-down {
    position: absolute;
    bottom: 70px;
    left: 40px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
    display: none;

    @media (min-width: $largebp) {
      display: block;
    }

    svg {
      margin: 15px auto 0;
      animation: slideAndFade 2s ease-in-out infinite;
    }
  }
}
