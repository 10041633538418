.steps {
  // background: $green;
  color: $white;
  padding: 70px 0;

  @media (min-width: $largebp) {
    padding-right: 80px;
    padding-top: 160px;
  }

  &__flex {
    @media (min-width: $largebp) {
      display: flex;
      gap: 60px;
      align-items: center;
    }
  }

  &__images {
    margin-bottom: 40px;

    @media (min-width: $largebp) {
      width: 57%;
      margin-bottom: 0;
    }
  }

  &__text {
    @media (min-width: $largebp) {
      flex: 1;
      width: 43%;
    }
  }

  &__title {
    font-size: 24px;
    max-width: 450px;
    margin: 0 0 30px;
    text-transform: none;
    font-weight: 300;

    @media (min-width: $largebp) {
      border-radius: 50%;
      font-size: 45px;
    }
  }

  &__p {
    font-size: 14px;
    text-transform: none;
    max-width: 287px;
    font-family: $font;

    @media (min-width: $largebp) {
      font-size: 20px;
      max-width: none;
    }

    & + & {
      margin-top: 25px;
    }
  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .slick-next {
    right: -17px;
    background-image: url("./images/right-arrow-black.svg");
  }

  .slick-prev {
    left: -17px;
    background-image: url("./images/left-arrow-black.svg");
  }
}

.steps-icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 28px;
  font-size: 14px;
  margin-top: 50px;

  @media (min-width: $largebp) {
    font-size: 20px;
  }

  h5 {
    font-family: $font;
    font-size: 14px;
    margin-top: 5px;
  }

  &__icon {
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid $white;

    svg {
      margin: 0 auto;
      height: 60px;
      width: auto;

      @media (min-width: $largebp) {
        height: auto;
      }
    }
  }
}
