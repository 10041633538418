.water {
  position: relative;
  padding-bottom: 40px;

  @media (min-width: $largebp) {
    padding-right: 80px;
    padding-bottom: 0;
  }
}

.map {
  position: relative;
  background-size: cover;
  background-position: right center;
  height: 600px;

  @media (min-width: $largebp) {
    padding-bottom: 56.25%;
    height: auto;
    width: 100%;
  }
}

.map-map {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  img {
    height: 100%;
    object-fit: cover;
    object-position: right;

    @media (min-width: 800px) {
      width: 100%;
    }
  }
}

.map-point {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  font-size: 20;
  color: white;
  background: #3d1913;
  position: absolute;
  font-size: 8px;

  @media (min-width: 800px) {
    transform: scale(1.25);
  }

  @media (min-width: $xlargebp) {
    transform: scale(1.75);
  }

  &--blue {
    background: #6fa6a4;
  }

  &--orange {
    background-color: #a37636;
  }

  &--green {
    background-color: #85c770;
  }

  &--1 {
    top: 265px;
    right: 210px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 29%;
      margin-top: -3%;
    }
  }

  &--2 {
    top: 115px;
    right: 137px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 36%;
      margin-top: -17.5%;
    }
  }

  &--3 {
    top: 108px;
    right: 120px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 37.6%;
      margin-top: -18.4%;
    }
  }

  &--4 {
    top: 103px;
    right: 133px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 36.3%;
      margin-top: -18.8%;
    }
  }

  &--5 {
    top: 116px;
    right: 149px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 34.7%;
      margin-top: -17.8%;
    }
  }

  &--6 {
    top: 129px;
    right: 153px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 33.6%;
      margin-top: -16.5%;
    }
  }

  &--7 {
    top: 81px;
    right: 136px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 36.4%;
      margin-top: -20.5%;
    }
  }

  &--8 {
    top: 146px;
    right: 388px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 12.3%;
      margin-top: -14.2%;
    }
  }

  /*
    Blue 
  */

  &--9 {
    top: 147px;
    right: 363px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 14.8%;
      margin-top: -14.2%;
    }
  }

  &--10 {
    top: 140px;
    right: 304px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 20.6%;
      margin-top: -14.8%;
    }
  }

  &--11 {
    top: 172px;
    right: 363px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 15.2%;
      margin-top: -12.2%;
    }
  }

  &--12 {
    top: 181px;
    right: 349px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 16.5%;
      margin-top: -11.4%;
    }
  }

  &--13 {
    top: 173px;
    right: 325px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 18.9%;
      margin-top: -11.7%;
    }
  }

  &--14 {
    top: 192px;
    right: 313px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 19.8%;
      margin-top: -10.1%;
    }
  }

  &--15 {
    top: 159px;
    right: 259px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 24.9%;
      margin-top: -12.8%;
    }
  }

  &--16 {
    top: 195px;
    right: 258px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 24.6%;
      margin-top: -9.4%;
    }
  }

  &--17 {
    top: 195px;
    right: 258px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 23.2%;
      margin-top: -7.2%;
    }
  }

  &--18 {
    top: 255px;
    right: 291px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 21.7%;
      margin-top: -4.2%;
    }
  }

  &--19 {
    top: 264px;
    right: 276px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 23%;
      margin-top: -3.4%;
    }
  }
  &--20 {
    top: 224px;
    right: 203px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 30.4%;
      margin-top: -7.6%;
    }
  }

  &--21 {
    top: 171px;
    right: 198px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 31%;
      margin-top: -11.9%;
    }
  }

  /*
    Orange
  */

  &--22 {
    top: 115px;
    right: 96px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 39.9%;
      margin-top: -17.6%;
    }
  }

  &--23 {
    top: 192px;
    right: 296px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 21.5%;
      margin-top: -10.1%;
    }
  }

  &--24 {
    top: 78px;
    right: 192px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 30.7%;
      margin-top: -20.8%;
    }
  }

  &--25 {
    top: 385px;
    right: 19px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 47.1%;
      margin-top: 8%;
    }
  }

  &--26 {
    top: 152px;
    right: 207px;

    @media (min-width: $largebp) {
      top: 50%;
      left: 50%;
      right: auto;
      margin-left: 29.1%;
      margin-top: -14.3%;
    }
  }
}

.map-box {
  background-color: $green;
  color: $white;
  padding: 24px;

  @media (min-width: $largebp) {
    width: 35%;
    max-width: 500px;
    top: 20px;
    left: 20px;
    height: calc(100% - 40px);
    position: absolute;
  }

  @media (min-width: $xxlargebp) {
    top: 50px;
    left: 50px;
    height: calc(100% - 100px);
  }

  @media (min-width: $xxlargebp) {
    padding: 40px;
  }

  h2 {
    font-weight: 300;
    text-align: center;

    @media (min-width: $xlargebp) {
      text-align: left;
      font-size: 45px;
    }
  }

  br {
    display: none;

    @media (min-width: $xxlargebp) {
      display: block;
    }
  }
}

.map-toggles {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 8px;
  margin: 24px 0;
  max-width: 290px;
  margin: 24px auto;

  @media (min-width: $xxlargebp) {
    margin: 32px auto;
  }
}

.map-toggle {
  display: flex;
  justify-content: space-between;
  line-height: 22px;
  border: 1px solid $white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 0 6px 0 12px;
  font-size: 12px;

  @media (min-width: $xlargebp) {
    line-height: 36px;
    font-size: 16px;
    padding-right: 9px;
  }

  &:hover,
  &--active {
    background: $white;
    color: $darkGreen;
  }

  .color {
    width: 15px;
    height: 15px;
    border-radius: 50%;

    @media (min-width: $xlargebp) {
      width: 23px;
      height: 23px;
    }

    &--dark-red {
      background: #3d1913;
    }

    &--blue {
      background: #6fa6a4;
    }

    &--orange {
      background-color: #a37636;
    }

    &--green {
      background-color: #85c770;
    }
  }
}

.map-nums {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.map-num {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  gap: 8px;
  font-weight: 500;

  @media (min-width: $xlargebp) {
    font-size: 15px;
  }

  &__circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-family: $font2;

    @media (min-width: $xlargebp) {
      width: 22px;
      height: 22px;
      font-size: 11px;
    }

    @media (min-width: $xxlargebp) {
      width: 24px;
      height: 24px;
      font-size: 12px;
    }

    &--dark-red {
      background: #3d1913;
    }

    &--blue {
      background: #6fa6a4;
    }

    &--orange {
      background-color: #a37636;
    }

    &--green {
      background-color: #85c770;
    }
  }
}
