.thrive {
  background: $darkGreen;
  color: $white;
  padding-bottom: 100px;

  @media (min-width: $largebp) {
    padding: 120px 0;
  }

  &__map-mobile {
    margin-bottom: 100px;
    width: 100%;
    max-width: none;

    @media (min-width: $largebp) {
      display: none;
    }
  }

  &__flex {
    @media (min-width: $largebp) {
      display: flex;
      gap: 40px;
      justify-content: space-between;
    }

    @media (min-width: $xlargebp) {
      gap: 120px;
    }
  }

  &__text {
    @media (min-width: $largebp) {
      width: calc(50% - 40px);
      max-width: 600px;
    }
  }

  &__title {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 400;

    @media (min-width: $largebp) {
      font-size: 45px;
    }
  }

  &__subtitle {
    max-width: 284px;
    font-size: 14px;
    margin-top: 20px;

    @media (min-width: $largebp) {
      font-size: 20px;
      max-width: 610px;
    }
  }

  &__image {
    display: none;

    @media (min-width: $largebp) {
      display: block;
      flex: 1;
      max-width: 940px;
    }
  }
}

.thrive-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 50px;
}

.thrive-item {
  display: flex;
  gap: 16px;

  h3 {
    color: $limeGreen;
    font-size: 28px;
    font-weight: 300;
    line-height: 1;
    width: 32px;
    text-align: center;
    font-family: $font;
    letter-spacing: -0.02em;

    @media (min-width: $largebp) {
      font-size: 54px;
      width: 70px;
    }

    @media (min-width: $xlargebp) {
      font-size: 72px;
      width: 90px;
    }
  }

  &__right {
    flex: 1;
    font-size: 14px;
    margin-top: 0px;

    @media (min-width: $largebp) {
      font-size: 18px;
      margin-top: 8px;
    }

    @media (min-width: $xlargebp) {
      font-size: 20px;
      margin-top: 10px;
    }
  }

  h4 {
    font-weight: 600;
    margin-bottom: 5px;
  }
}
